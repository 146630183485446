<template v-slot:extention v-if="needValidate">
	<v-autocomplete
		v-model="selected_value"
		v-bind:disabled="disabled"
		:items="countries"
		label="Country"
		item-text="name"
		item-value="id"
		:error-messages="errorMessages"
		@change="$emit('change')"
		@blur="$emit('blur')"
	/>
</template>

<script>
import { mapState } from "vuex";

export default {
	props: ["value", "disabled", "errorMessages"],

	watch: {
		value: function (newValue, oldValue) {
			if (newValue !== this.selected_value) this.selected_value = newValue;
		},
	},

	computed: {
		...mapState(["countries"]),

		selected_value: {
			get: function () {
				return this.value;
			},
			set: function (newValue) {
				this.selected = newValue;
				this.$emit("input", newValue);
			},
		},
	},

	methods: {
		onChange() {
			this.$emit("change");
		},

		onBlur() {
			this.$emit("blur");
		},

		handleSelect(newValue) {
			this.selected_value = newValue;
		},
	},
};
</script>
