import Vue from "vue";
import Vuex from "vuex";
import { state, getters, mutations, actions } from "./modules/root";
import ui from "./modules/ui";
import auth from "./modules/auth";
import file from "./modules/file";
import root from "./modules/root";

Vue.use(Vuex);

export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	modules: {
		ui,
		auth,
		file,
		root,
	},
});
