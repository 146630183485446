<template>
	<v-select
		v-model="selected_value"
		v-bind:disabled="disabled"
		:items="options"
		label="Status"
		item-text="title"
		item-value="value"
	/>
</template>
​
<script>
export default {
	props: ["value", "disabled"],
	watch: {
		value: function (newValue, oldValue) {
			if (newValue !== this.selected_value) this.selected_value = newValue;
		},
	},
	data() {
		return {
			options: [
				{
					title: "Deleted",
					value: -1,
				},
				{
					title: "Passive",
					value: 0,
				},
				{
					title: "Active",
					value: 1,
				},
			],
		};
	},
	computed: {
		selected_value: {
			get: function () {
				return this.value;
			},
			set: function (newValue) {
				this.$emit("input", newValue);
			},
		},
	},
	methods: {
		handleSelect(newValue) {
			this.selected_value = newValue;
		},
	},
};
</script>
