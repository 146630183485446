import Vue from "vue";
// import Base64 from "js-base64";
const Base64 = require("js-base64");

let refreshTimeout = null;

const state = {
	accessToken: null,
	expiresAt: null,
	refreshToken: null,
	timerHasStarted: false,
	user: null,
	// prepared: false,
};

const getters = {
	isAuthenticated: (state) => state.timerHasStarted,
};

const mutations = {
	SET_TOKEN_DATA(state, { accessToken, expiresAt, refreshToken }) {
		state.accessToken = accessToken;
		state.expiresAt = expiresAt;
		state.refreshToken = refreshToken;
		state.user = combOutUser(accessToken);
	},
	SET_TIMER_HAS_STARTED: (state, value) => (state.timerHasStarted = value),
};

const actions = {
	// async prepare({ state, commit, dispatch }) {
	// 	const tokenData = Vue.prototype.$ls.get("token_data");
	// 	if (tokenData) {
	// 		await commit("SET_TOKEN_DATA", tokenData);
	// 		dispatch("startRefreshTimer");
	// 	}
	// 	await commit("SET_PREPARED", true);
	// },

	async handleTokenData(
		{ commit, dispatch },
		{ accessToken, expiresAt, refreshToken }
	) {
		Vue.prototype.$ls.set("token_data", {
			accessToken,
			expiresAt,
			refreshToken,
		});
		await commit("SET_TOKEN_DATA", { accessToken, expiresAt, refreshToken });
		dispatch("startRefreshTimer");
	},

	async deleteTokenData({ commit }, { accessToken, expiresAt, refreshToken }) {
		await Vue.prototype.$ls.remove("token_data");
		await commit("SET_TOKEN_DATA", { accessToken, expiresAt, refreshToken });
	},

	signIn(context, { identifier, password, onsuccess, onFail }) {
		const body = {
			identifier,
			password,
			appCode: process.env.VUE_APP_AUTH_APP_CODE,
			deviceName: "Some Browser",
		};
		Vue.axios
			.post(`${process.env.VUE_APP_AUTH_BASE_URL}signIn`, body)
			.then(async (response) => {
				await context.dispatch("handleTokenData", response.data);
				if (onsuccess) onsuccess();
			})
			.catch((e) => {
				if (onFail) onFail(e);
			});
	},

	signUp(context, { email, password, firstName, lastName, onsuccess, onFail }) {
		const body = {
			email,
			password,
			firstName,
			lastName,
			appCode: process.env.VUE_APP_AUTH_APP_CODE,
			deviceName: "Some Browser",
		};
		Vue.axios
			.post(`${process.env.VUE_APP_AUTH_BASE_URL}signup`, body)
			.then(async (response) => {
				await context.dispatch("handleTokenData", response.data);
				if (onsuccess) onsuccess();
			})
			.catch((e) => {
				if (onFail) onFail(e);
			});
	},

	signOut(context, { onsuccess, onFail }) {
		const stateData = {
			accessToken: "",
			expiresAt: "",
			refreshToken: "",
		};
		const tokenData = Vue.prototype.$ls.get("token_data");

		Vue.axios
			.delete(`${process.env.VUE_APP_AUTH_BASE_URL}signOut`, {
				headers: { authorization: `Bearer ${tokenData.accessToken}` },
			})
			.then(async () => {
				await context.dispatch("deleteTokenData", stateData);
				if (onsuccess) onsuccess();
			})
			.catch(async (e) => {
				if (onFail) onFail(e);
				await context.dispatch("deleteTokenData", stateData);
				onsuccess();
			});
	},

	refresh(context) {
		const refreshToken = Vue.prototype.$ls.get("token_data").refreshToken;
		if (refreshToken) {
			Vue.axios
				.post(`${process.env.VUE_APP_AUTH_BASE_URL}refresh/${refreshToken}`)
				.then(async (response) => {
					await context.dispatch("handleTokenData", response.data);
					context.dispatch("startRefreshTimer");
				})
				.catch((e) => {
					if (onFail) onFail(e);
				});
		} else console.warn("auth", "No refresh token found");
	},

	async startRefreshTimer({ commit, dispatch, state }) {
		if (state.expiresAt && state.accessToken) {
			const now = Number(new Date());
			const diff = state.expiresAt - now - 6000;
			if (diff > 0) {
				await commit("SET_TIMER_HAS_STARTED", true);
				return true;
			} else {
				refreshTimeout = setTimeout(() => {
					dispatch("refresh");
				}, diff);
				return false;
			}
		}
	},

	stopRefreshTimer({ commit }) {
		if (refreshTimeout) {
			clearTimeout(refreshTimeout);
			refreshTimeout = null;
			commit("SET_TIMER_HAS_STARTED", false);
		}
	},
};

function combOutUser(accessToken) {
	const [part1, part2, part3] = accessToken.split(".");
	if (part1 && part2 && part3) {
		const tokenDataJson = JSON.parse(Base64.decode(part2));
		return tokenDataJson.user;
	}
}

export const UserType = {
	default: 0,
	admin: 1,
	super: 9,
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
	UserType,
};
