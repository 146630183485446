<template>
	<v-main>
		<!-- <v-app-bar app clipped-left>
      <v-toolbar-title>Antrego Admin</v-toolbar-title>
    </v-app-bar>-->
		<v-navigation-drawer app clipped color="accent">
			<v-list dense>
				<v-list-item
					v-for="(item, i) in listItems"
					:key="i"
					link
					:to="item.url"
				>
					<v-list-item-action>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<template v-slot:append>
				<div class="pa-2">
					<v-btn block @click="logOut()">Logout</v-btn>
				</div>
			</template>
		</v-navigation-drawer>

		<router-view />
	</v-main>
</template>

<script>
import { mapActions } from "vuex";
import { UserType } from "../../store/modules/auth";

export default {
	data: () => ({
		listItems: [
			{ icon: "mdi-view-dashboard", title: "Dashboard", url: "/" },
			{ icon: "mdi-domain", title: "Companies", url: "/companies" },
			{ icon: "mdi-earth", title: "Countries", url: "/countries" },
			{ icon: "mdi-cash-multiple", title: "Currencies", url: "/currencies" },
			{ icon: "mdi-truck", title: "Shipping Terms", url: "/shipping_terms" },
			{ icon: "mdi-weight-gram", title: "Units", url: "/units" },
			{ icon: "mdi-account-key", title: "Users", url: "/users" },
			{
				icon: "mdi-application-settings",
				title: "Applications",
				url: "/applications",
				requiresSuperUser: true,
			},
			{ icon: "mdi-apps", title: "AntProducts", url: "/ant_products" },
		],
	}),

	methods: {
		...mapActions("auth", ["signOut"]),
		logOut() {
			this.showProgressBar();
			this.signOut({
				onsuccess: () => {
					this.hideProgressBar();
					this.$router.go("sign_in");
				},
				onFail: (e) => console.error(e),
			});
		},
	},
	created() {
		if (this.user.type < UserType.super) {
			this.listItems = this.listItems.filter((a) => !a.requiresSuperUser);
		}
	},
};
</script>
