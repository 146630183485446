import Vue from "vue";

const actions = {
	sendFile({}, { file, folder, onsuccess, onFail }) {
		if (file) {
			let formData = new FormData();
			formData.append("file", file);
			Vue.axios
				.post(`${process.env.VUE_APP_FS_BASE_URL}${folder}`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					if (onsuccess) onsuccess(response);
				})
				.catch((e) => {
					if (onFail) onFail(e);
				});
		}
	},
	loadFile({}, { name, folder, onsuccess, onFail }) {
		Vue.axios
			.get(`${process.env.VUE_APP_FS_BASE_URL}${folder}/${name}`)
			.then((response) => {
				console.log("RES", response);
				if (onsuccess) onsuccess(response);
			})
			.catch((e) => {
				if (onFail) onFail(e);
			});
	},
};

export default {
	namespaced: true,
	actions,
	state: {},
	mutations: {},
	getters: {},
};
