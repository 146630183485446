<template>
	<v-main class="background">
		<v-container class="fill-height" fluid>
			<v-row align="center" justify="center">
				<v-col cols="12" sm="8" md="6" lg="4">
					<v-form lazy-validation>
						<v-card outlined max-width="500" light>
							<v-card-text>
								<v-card-title>Login</v-card-title>
								<v-text-field
									prepend-icon="mdi-email"
									clearable="clearable"
									type="email"
									v-model="email"
									:error-messages="emailErrors"
									label="E-mail"
									required
									@input="$v.email.$touch()"
									@blur="$v.email.$touch()"
								></v-text-field>
								<v-text-field
									prepend-icon="mdi-lock"
									clearable="clearable"
									type="password"
									v-model="password"
									:error-messages="passwordErrors"
									label="Password"
									required
									@input="$v.password.$touch()"
									@blur="$v.password.$touch()"
								></v-text-field>
								<!-- <v-checkbox label="Remember me."></v-checkbox> -->
							</v-card-text>
							<v-card-actions>
								<v-btn block color="primary" @click="submit()">Submit</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { UserType } from "../../store/modules/auth";

export default {
	mixins: [validationMixin],

	validations: {
		email: { required, email },
		password: { required },
	},

	data: () => ({
		email: "",
		password: "",
	}),

	computed: {
		emailErrors() {
			const errors = [];
			if (this.$v.email.$dirty) {
				!this.$v.email.email && errors.push("Must be valid e-mail");
				!this.$v.email.required && errors.push("E-mail is required");
			}
			return errors;
		},
		passwordErrors() {
			const errors = [];
			if (this.$v.password.$dirty)
				!this.$v.password.required && errors.push("Password is required.");
			return errors;
		},
	},

	methods: {
		...mapActions("auth", ["signIn"]),

		submit() {
			this.showProgressBar();
			this.signIn({
				identifier: this.email,
				password: this.password,
				onsuccess: () => {
					if (this.user.type > UserType.default) {
						this.hideProgressBar();
						this.$router.go({ name: "home" });
					}
					// TODO: showAlertDialog for unauthorizeLogin
				},
				onFail: (e) => console.error(e),
			});
		},
	},
};
</script>

<style>
.background {
	background-image: linear-gradient(
			to right bottom,
			rgb(50, 61, 216),
			rgba(0, 0, 0, 0.52)
		),
		/* url("https://picsum.photos/1920/1080?grayscale&blur=7"); */
			url("https://picsum.photos/id/403/1920/1080?blur=7&grayscale");
	background-position: center center;
}
</style>
