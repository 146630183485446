import { mapState, mapActions } from "vuex";

export default global = {
	computed: {
		...mapState("root", [
			"countries",
			"currencies",
			// "ports",
			"shippingTerms",
			"units",
		]),
		...mapState("auth", ["user"]),
	},

	methods: {
		...mapActions("ui", [
			"showProgressBar",
			"hideProgressBar",
			"showAlertDialog",
			"hideAlertDialog",
			"showConfirmDialog",
			"hideConfirmDialog",
		]),

		findCountry(id) {
			return this.countries.find((country) => country.id === id);
		},
		findCurrency(id) {
			return this.currencies.find((currency) => currency.id === id);
		},
		findPort(id) {
			return this.ports.find((port) => port.id === id);
		},
		findShippingTerm(id) {
			return this.shippingTerms.find(
				(shippingTerms) => shippingTerms.id === id
			);
		},
		findUnit(id) {
			return this.units.find((unit) => unit.id === id);
		},
	},
};
