import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vuetify.config.silent = false; // suppresses all Vuetify logs and warnings when true.

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: true,
		// default: "dark",
		options: {
			customProperties: true,
		},
		themes: {
			dark: {
				primary: "#673ab7",
				secondary: "#00bcd4",
				accent: "#512da8",
				error: "#ffc107",
				info: "#607d8b",
				success: "#cddc39",
				warning: "#ff5722",
				anchor: "#FFFFFF",
			},
		},
		icons: {
			iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
		},
	},
});
