<template>
	<v-dialog v-bind:value="value" max-width="360">
		<v-card>
			<v-card-title class="headline">{{ title }}</v-card-title>
			​
			<v-card-text>{{ message }}</v-card-text>
			​
			<v-card-actions>
				<v-spacer />
				<v-btn text @click="handleClose">{{ buttontext || "OK" }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
​
<script>
export default {
	props: ["value", "title", "message", "buttontext"],
	methods: {
		handleClose() {
			this.$emit("input", false);
		},
	},
};
</script>
