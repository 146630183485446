<template>
	<v-app id="inspire">
		<router-view v-if="isReady" />
		<v-progress-linear
			v-if="progressBar.visibility || !isReady"
			:indeterminate="progressBar.animating"
			:color="progressBar.color"
			absolute
			top
		/>
		<v-snackbar
			v-model="snackBar.visibility"
			bottom
			:color="snackBar.color"
			:timeout="snackBar.timeout"
		>
			{{ snackBar.message }}
			<v-btn icon @click="$store.dispatch('ui/hideSnackBar')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-snackbar>
		<alert-dialog
			v-model="alertDialog.visibility"
			:title="alertDialog.title"
			:message="alertDialog.message"
			buttontext="OK"
		/>
	</v-app>
</template>

<script>
import AlertDialog from "./components/partial/dialog/AlertDialog";
import ConfirmDialog from "./components/partial/dialog/ConfirmDialog";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
	components: {
		AlertDialog,
		ConfirmDialog,
	},

	props: {
		source: String,
	},

	data: () => ({}),

	computed: {
		...mapState("ui", [
			"progressBar",
			"snackBar",
			"alertDialog",
			"confirmDialog",
		]),
		...mapGetters(["isReady"]),
	},

	methods: {
		...mapActions(["loadStructure"]),
	},

	created() {
		this.loadStructure({ url: `countries`, type: "SET_COUNTRIES" });
		this.loadStructure({ url: `currencies`, type: "SET_CURRENCIES" });
		// this.loadStructure({ url: `ports`, type: "SET_PORTS" });
		this.loadStructure({
			url: `shipping_terms`,
			type: "SET_SHIPPING_TERMS",
		});
		this.loadStructure({ url: `units`, type: "SET_UNITS" });
	},
};
</script>

<style scoped>
.v-progress-linear {
	z-index: 1000;
}
html {
	overflow-y: auto !important;
}
</style>
