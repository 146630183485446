<template>
	<page-template>
		<template slot="title">Currencies</template>
		<template slot="content">
			<v-data-table
				:headers="headers"
				:items="this.currencies"
				:items-per-page="10"
				class="ma-4"
				style="curser: pointer"
				@click:row="dialog.show"
				:search="search"
				:custom-filter="filterText"
			>
				<template v-slot:top>
					<v-text-field hide-details filled v-model="search" label="Search" />
				</template>
			</v-data-table>

			<v-fab-transition>
				<v-btn
					color="secondary"
					absolute
					bottom
					right
					fab
					class="mb-12"
					@click="dialog.show(null)"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Currency</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field
							label="Name"
							v-model="dialog.item.name"
							:error-messages="nameErrors"
							@change="$v.dialog.item.name.$touch()"
							@input="$v.dialog.item.name.$touch()"
							@blur="$v.dialog.item.name.$touch()"
						/>
						<v-text-field
							label="Code"
							counter="3"
							v-model="dialog.item.code"
							:error-messages="codeErrors"
							@change="$v.dialog.item.code.$touch()"
							@input="$v.dialog.item.code.$touch()"
							@blur="$v.dialog.item.code.$touch()"
						/>
						<v-text-field
							label="Symbol"
							counter="3"
							v-model="dialog.item.symbol"
							:error-messages="symbolErrors"
							@change="$v.dialog.item.symbol.$touch()"
							@input="$v.dialog.item.symbol.$touch()"
							@blur="$v.dialog.item.symbol.$touch()"
						/>
						<status-select
							v-model="dialog.item.status"
							v-if="!dialog.item.new"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn color="warning" text @click="deleteCurrency(dialog.item)">
							Delete
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveCurrency(dialog.item)">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import PageTemplate from "../templates/PageTemplate";
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import StatusSelect from "../controls/StatusSelect";
import { mapState, mapActions } from "vuex";

export default {
	name: "Currecies",
	mixins: [validationMixin],
	components: { PageTemplate, StatusSelect },

	validations: {
		dialog: {
			item: {
				name: {
					required,
					maxLength: maxLength(20),
				},
				code: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(3),
				},
				symbol: {
					required,
					maxLength: maxLength(3),
				},
			},
		},
	},

	computed: {
		...mapState(["currencies"]),

		nameErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty) {
				!this.$v.dialog.item.name.required && errors.push("Name is required");
				!this.$v.dialog.item.name.maxLength &&
					errors.push("Name must be max 20 characters long");
			}
			return errors;
		},

		codeErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty) {
				!this.$v.dialog.item.code.required && errors.push("Code is required");
				!this.$v.dialog.item.code.minLength &&
					errors.push("Code must be 3 characters long");
				!this.$v.dialog.item.code.maxLength &&
					errors.push("Code must be 3 characters long");
			}
			return errors;
		},

		symbolErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty) {
				!this.$v.dialog.item.symbol.required &&
					errors.push("Symbol is required");
				!this.$v.dialog.item.symbol.maxLength &&
					errors.push("Symbol must be max 3 characters long");
			}
			return errors;
		},
	},

	data() {
		return {
			search: "",

			dialog: {
				visibility: false,
				item: null,
				show: (currency) => {
					this.dialog.item = currency
						? Object.assign({}, currency)
						: {
								name: "",
								code: "",
								symbol: "",
								status: 1,
								new: true,
						  };
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
			},
			headers: [
				{ text: "Name", value: "name" },
				{ text: "Code", value: "code" },
				{ text: "Symbol", value: "symbol" },
				{ text: "Status", value: "status" },
			],
		};
	},

	// TODO: Is it neccesary?
	created() {
		this.loadCurrencies();
	},

	methods: {
		...mapActions(["loadStructure"]),

		filterText(value, search, item) {
			return (
				value != null &&
				search != null &&
				typeof value === "string" &&
				value.toString().toLocaleLowerCase().indexOf(search.toLowerCase()) !==
					-1
			);
		},

		loadCurrencies() {
			this.loadStructure({ url: `currencies`, type: "SET_CURRENCIES" });
		},

		saveCurrency(currency) {
			this.$v.dialog.item.$touch();
			if (currency.id) {
				this.$http
					.put(`currencies/${currency.id}`, currency, {})
					.then((response) => {
						this.dialog.hide();
						this.loadCurrencies();
					});
			} else {
				this.$http.post(`currencies`, currency, {}).then((response) => {
					this.dialog.hide();
					this.loadCurrencies();
				});
			}
		},

		deleteCurrency(currency) {
			if (currency.id) {
				this.$http
					.delete(`currencies/${currency.id}`, currency, {})
					.then((response) => {
						this.dialog.hide();
						this.loadCurrencies();
					});
			} else this.dialog.hide();
		},
	},
};
</script>
