<template>
	<page-template>
		<template slot="title">Antrego Products</template>
		<template slot="content">
			<v-data-table
				:headers="headers"
				:items="antProducts"
				:items-per-page="10"
				class="ma-4"
				style="curser: pointer"
				@click:row="dialog.show"
				:search="search"
				:custom-filter="filterText"
			>
				<template v-slot:top>
					<v-text-field hide-details filled v-model="search" label="Search" />
				</template>
			</v-data-table>

			<v-fab-transition>
				<v-btn color="secondary" absolute bottom right fab class="mb-12" @click="dialog.show(null)">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Antrego Products</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field
							label="Name (*)"
							v-model="dialog.item.name"
							counter="32"
							:error-messages="nameErrors"
							@change="$v.dialog.item.name.$touch()"
							@input="$v.dialog.item.name.$touch()"
							@blur="$v.dialog.item.name.$touch()"
						/>
						<v-row v-for="(item, i) in dialog.item.contents" :key="i">
							<v-col cols="6">
								<v-select
									label="Module (*)"
									v-model="item.module"
									:items="moduleList"
									item-text="title"
									item-value="value"
									:menu-props="{ maxHeight: '400' }"
									disabled
								/>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Limit (*)"
									v-model="item.limit"
									type="number"
									:error-messages="durationValueErrors"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-text-field
									label="Duration/Value (*)"
									v-model="dialog.item.duration.value"
									type="number"
									:error-messages="durationValueErrors"
									@change="$v.dialog.item.duration.value.$touch()"
									@input="$v.dialog.item.duration.value.$touch()"
									@blur="$v.dialog.item.duration.value.$touch()"
								/>
							</v-col>
							<v-col cols="6">
								<v-select
									label="Duration/Unit (*)"
									v-model="dialog.item.duration.unit"
									:items="unitTypes"
									item-text="title"
									item-value="value"
									:error-messages="durationUnitErrors"
									@change="$v.dialog.item.duration.unit.$touch()"
									@input="$v.dialog.item.duration.unit.$touch()"
									@blur="$v.dialog.item.duration.unit.$touch()"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-text-field
									label="Price (USD) (*)"
									v-model="dialog.item.price.usd"
									prefix="$"
									type="number"
									:error-messages="priceUsdErrors"
									@change="$v.dialog.item.price.usd.$touch()"
									@input="$v.dialog.item.price.usd.$touch()"
									@blur="$v.dialog.item.price.usd.$touch()"
								/>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Price (TL) (*)"
									v-model="dialog.item.price.tl"
									prefix="₺"
									type="number"
									:error-messages="priceTlErrors"
									@change="$v.dialog.item.price.tl.$touch()"
									@input="$v.dialog.item.price.tl.$touch()"
									@blur="$v.dialog.item.price.tl.$touch()"
								/>
							</v-col>
						</v-row>
						<v-select
							label="Category (*)"
							v-model="dialog.item.category"
							:items="categoryTypes"
							item-text="title"
							item-value="value"
							:error-messages="categoryErrors"
							@change="$v.dialog.item.category.$touch()"
							@input="$v.dialog.item.category.$touch()"
							@blur="$v.dialog.item.category.$touch()"
						/>

						<!-- TODO: Convert localizedString -->
						<v-text-field
							label="Description"
							v-model="dialog.item.description"
							counter="100"
							:error-messages="descriptionErrors"
							@change="$v.dialog.item.description.$touch()"
							@input="$v.dialog.item.description.$touch()"
							@blur="$v.dialog.item.description.$touch()"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn color="warning" text @click="deleteAntProduct(dialog.item)"> Delete </v-btn>
						<v-spacer></v-spacer>
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveAntProduct(dialog.item)"> Save </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import PageTemplate from "../templates/PageTemplate";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import StatusSelect from "../controls/StatusSelect";

export default {
	name: "AntregoProduct",
	mixins: [validationMixin],
	components: { PageTemplate, StatusSelect },

	validations: {
		dialog: {
			item: {
				name: { required, minLength: minLength(3), maxLength: maxLength(32) },
				modules: { required },
				duration: {
					value: { required },
					unit: { required },
				},
				price: {
					usd: { required },
					tl: { required },
				},
			},
		},
	},

	computed: {
		nameErrors() {
			const errors = [];
			if (this.$v.dialog.item.name.$dirty) {
				!this.$v.dialog.item.name.required && errors.push("Name is required");
				!this.$v.dialog.item.name.minLength && errors.push("Name should be min 3 characters");
				!this.$v.dialog.item.name.maxLength && errors.push("Name should be max 32 characters");
			}
			return errors;
		},

		modulesErrors(i) {
			const errors = [];
			if (this.$v.dialog.item.contents[i].module.$dirty) {
				!this.$v.dialog.item.contents[i].module.required && errors.push("Module is required");
			}
			return errors;
		},

		durationValueErrors() {
			const errors = [];
			if (this.$v.dialog.item.duration.value.$dirty) {
				!this.$v.dialog.item.duration.value.required && errors.push("Duration value is required");
			}
			return errors;
		},

		durationUnitErrors() {
			const errors = [];
			if (this.$v.dialog.item.duration.unit.$dirty) {
				!this.$v.dialog.item.duration.unit.required && errors.push("Duration unit is required");
			}
			return errors;
		},

		priceUsdErrors() {
			const errors = [];
			if (this.$v.dialog.item.price.usd.$dirty) {
				!this.$v.dialog.item.price.usd.required && errors.push("Price is required");
				!(this.dialog.item.price.usd >= 0) && errors.push("Price should be minimum 0");
			}
			return errors;
		},

		priceTlErrors() {
			const errors = [];
			if (this.$v.dialog.item.price.tl.$dirty) {
				!this.$v.dialog.item.price.tl.required && errors.push("Price is required");
				!(this.dialog.item.price.tl >= 0) && errors.push("Price should be minimum 0");
			}
			return errors;
		},

		categoryErrors() {
			const errors = [];
			if (this.$v.dialog.item.category?.$dirty) {
				!this.$v.dialog.item.category.required && errors.push("Category is required");
			}
			return errors;
		},

		descriptionErrors() {
			const errors = [];
			if (this.$v.dialog.item.name.$dirty) {
				!this.$v.dialog.item.name.maxLength && errors.push("Name should be max 100 characters");
			}
			return errors;
		},
	},

	data() {
		return {
			search: "",
			antProducts: [],

			moduleList: [
				{
					title: "Partner",
					value: "partner",
				},
				{
					title: "Shipment",
					value: "shipment",
				},
				{
					title: "User",
					value: "user",
				},
				{
					title: "Storage",
					value: "storage",
				},
			],

			unitTypes: [
				{
					title: "Day",
					value: "day",
				},
				{
					title: "Week",
					value: "week",
				},
				{
					title: "Month",
					value: "month",
				},
				{
					title: "Year",
					value: "year",
				},
			],

			categoryTypes: [
				{
					title: "Free",
					value: "free",
				},
				{
					title: "Premium",
					value: "premium",
				},
				{
					title: "Premium Plus",
					value: "premium_plus",
				},
			],

			dialog: {
				visibility: false,
				item: null,
				show: (antProduct) => {
					this.dialog.item = antProduct
						? Object.assign({}, antProduct)
						: {
								name: null,
								contents: [],
								duration: {
									value: null,
									unit: "", // TODO: fix for unit type
								},
								price: {
									usd: null,
									tl: null,
								},
								category: null,
						  };
					if (!antProduct) {
						for (const module of this.moduleList) {
							this.dialog.item.contents.push({
								module: module.value,
								limit: null,
							});
						}
					}
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
			},
			headers: [
				{ text: "Category", value: "category" },
				{ text: "Name", value: "name" },
				{ text: "Duration/Value", value: "duration.value" },
				{ text: "Duration/Unit", value: "duration.unit" },
				{ text: "Price (usd)", value: "price.usd" },
				{ text: "Price (tl)", value: "price.tl" },
			],
		};
	},

	created() {
		this.loadAntProducts();
	},

	methods: {
		filterText(value, search, item) {
			return (
				value != null &&
				search != null &&
				typeof value === "string" &&
				value.toString().toLocaleLowerCase().indexOf(search.toLowerCase()) !== -1
			);
		},

		loadAntProducts() {
			this.$http.get("antrego/products?adminMode=true").then((response) => {
				this.antProducts = response.data.items;
			});
		},

		saveAntProduct(antProduct) {
			console.log("antProduct :>> ", antProduct);
			this.$v.dialog.item.$touch();
			if (antProduct.id) {
				this.$http.put(`antrego/products/${antProduct.id}`, antProduct, {}).then(() => {
					this.dialog.hide();
					this.loadAntProducts();
				});
			} else {
				this.$http.post(`antrego/products`, antProduct, {}).then((response) => {
					this.dialog.hide();
					this.loadAntProducts();
				});
			}
		},

		deleteAntProduct(antProduct) {
			if (antProduct.id) {
				this.$http.delete(`antrego/products/${antProduct.id}`, antProduct, {}).then((response) => {
					this.dialog.hide();
					this.loadAntProducts();
				});
			} else this.dialog.hide();
		},

		addNewModule() {
			this.dialog.item.contents.push({
				module: null,
				limit: null,
			});
		},
	},
};
</script>
