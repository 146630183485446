<template>
	<page-template>
		<template slot="title">Countries</template>
		<template slot="content" v-if="countries">
			<v-data-table
				:headers="headers"
				:items="this.countries"
				:items-per-page="10"
				class="ma-4"
				style="curser: pointer"
				@click:row="countryAction"
				:search="search"
				:custom-filter="filterText"
			>
				<template v-slot:top>
					<v-text-field hide-details filled v-model="search" label="Search" />
				</template>
			</v-data-table>

			<v-fab-transition>
				<v-btn
					color="secondary"
					absolute
					bottom
					right
					fab
					class="mb-12"
					@click="dialog.show(null)"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Country</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field
							label="Name"
							v-model="dialog.item.name"
							:error-messages="nameErrors"
							@change="$v.dialog.item.name.$touch()"
							@input="$v.dialog.item.name.$touch()"
							@blur="$v.dialog.item.name.$touch()"
						/>
						<v-text-field
							label="Code"
							counter="2"
							v-model="dialog.item.code"
							:error-messages="codeErrors"
							@change="$v.dialog.item.code.$touch()"
							@input="$v.dialog.item.code.$touch()"
							@blur="$v.dialog.item.code.$touch()"
						/>
						<status-select
							v-model="dialog.item.status"
							v-if="!dialog.item.new"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn color="warning" text @click="deleteCountry(dialog.item)"
							>Delete</v-btn
						>
						<v-spacer></v-spacer>
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveCountry(dialog.item)"
							>Save</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import PageTemplate from "../templates/PageTemplate";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import StatusSelect from "../controls/StatusSelect";
import { mapState, mapActions } from "vuex";

export default {
	name: "Countries",
	mixins: [validationMixin],
	components: { PageTemplate, StatusSelect },

	validations: {
		dialog: {
			item: {
				name: { required },
				code: { required, minLength: minLength(2), maxLength: maxLength(2) },
			},
		},
	},

	computed: {
		...mapState(["countries"]),

		nameErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty)
				!this.$v.dialog.item.name.required && errors.push("Name is required");
			return errors;
		},

		codeErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty) {
				!this.$v.dialog.item.code.required && errors.push("Code is required");
				!this.$v.dialog.item.code.minLength &&
					errors.push("Code must be 2 characters long");
				!this.$v.dialog.item.code.maxLength &&
					errors.push("Code must be 2 characters long");
			}
			return errors;
		},
	},

	data: () => ({
		search: "",

		dialog: {
			visibility: false,
			item: null,
			show(country) {
				this.item = country
					? Object.assign({}, country)
					: { name: "", code: "", ports: [], status: 1, new: true };
				this.visibility = true;
			},
			hide() {
				this.item = null;
				this.visibility = false;
			},
		},
		headers: [
			{ text: "Name", value: "name" },
			{ text: "Code", value: "code" },
			{ text: "Status", value: "status" },
		],
	}),

	// TODO: Is it neccesary?
	created() {
		this.loadCountries();
	},

	methods: {
		...mapActions(["loadStructure"]),

		filterText(value, search, item) {
			return (
				value != null &&
				search != null &&
				typeof value === "string" &&
				value.toString().toLocaleLowerCase().indexOf(search.toLowerCase()) !==
					-1
			);
		},

		countryAction(country) {
			this.$router.push({
				name: "country",
				params: { id: country.id },
			});
		},

		loadCountries() {
			this.loadStructure({
				url: `countries`,
				type: "SET_COUNTRIES",
			});
		},

		saveCountry(country) {
			this.$v.dialog.item.$touch();
			if (country.id) {
				this.$http
					.put(`countries/${country.id}`, country, {})
					.then((response) => {
						this.dialog.hide();
						this.loadCountries();
					});
			} else {
				this.$http.post(`countries`, country, {}).then((response) => {
					this.dialog.hide();
					this.loadCountries();
				});
			}
		},

		deleteCountry(country) {
			if (country.id) {
				this.$http
					.delete(`countries/${country.id}`, country, {})
					.then((response) => {
						this.dialog.hide();
						this.loadCountries();
					});
			} else this.dialog.hide();
		},
	},
};
</script>
