import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Storage from "vue-ls";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import globalMixin from "./mixins/global";

Vue.mixin(globalMixin);

Vue.use(Storage, {
	namespace: "extadmin__", // key prefix
	name: "ls", // name variable Vue.[ls] or this.[$ls],
	storage: "local", // storage name session, local, memory
});

const tokenData = Vue.ls.get("token_data");
if (tokenData) {
	store.dispatch("auth/handleTokenData", tokenData);
	store.dispatch("auth/startRefreshTimer");
}

axios.defaults.baseURL = process.env.VUE_APP_WS_BASE_URL;
axios.defaults.headers.common[
	"Authorization"
] = `Bearer ${store.state.auth.accessToken}`;
Vue.use(VueAxios, axios);

Vue.config.productionTip = process.env.NODE_ENV === "production"; // TODO: check if it works

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
