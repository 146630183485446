import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import MainLayout from "../components/layouts/MainLayout.vue";
import Home from "../components/pages/Home.vue";
import Units from "../components/pages/Units.vue";
import Countries from "../components/pages/Countries.vue";
import Country from "../components/pages/Country.vue";
import Currencies from "../components/pages/Currencies.vue";
import ShippingTerms from "../components/pages/ShippingTerms.vue";
import Companies from "../components/pages/Companies.vue";
import Company from "../components/pages/Company.vue";
import SignIn from "../components/pages/SignIn.vue";
import Error404 from "../components/pages/404.vue";
import Users from "../components/pages/Users.vue";
import Applications from "../components/pages/Applications.vue";
import AntProducts from "../components/pages/AntProducts.vue";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			component: MainLayout,
			children: [
				{
					path: "",
					name: "home",
					component: Home,
				},
				{
					path: "/units",
					name: "units",
					component: Units,
				},
				{
					path: "/countries",
					name: "countries",
					component: Countries,
				},
				{
					path: "/country/:id",
					name: "country",
					component: Country,
				},
				{
					path: "/currencies",
					name: "currencies",
					component: Currencies,
				},
				{
					path: "/shipping_terms",
					name: "shipping_terms",
					component: ShippingTerms,
				},
				{
					path: "/companies",
					name: "companies",
					component: Companies,
				},
				{
					path: "/company/:id",
					name: "company",
					component: Company,
				},
				{
					path: "/users",
					name: "users",
					component: Users,
				},
				{
					path: "/applications",
					name: "applications",
					component: Applications,
				},
				{
					path: "/ant_products",
					name: "ant_products",
					component: AntProducts,
				},
			],
		},
		{
			path: "/sign_in",
			name: "sign_in",
			component: SignIn,
		},
		{
			// Always leave this as last one
			path: "*",
			name: "404",
			component: Error404,
		},
		// {
		//   path: '/about',
		//   name: 'About',
		//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
		// }
	],
});

function requiresAuthorization(pageName) {
	if (pageName === "sign_in" || pageName === "signup") return false;
	else return true;
}

router.beforeEach((to, from, next) => {
	const isAuthenticated = store.getters["auth/isAuthenticated"];
	if (requiresAuthorization(to.name) && !isAuthenticated) {
		next("/sign_in");
	} else if (!requiresAuthorization(to.name) && isAuthenticated) {
		next("/");
	} else {
		next();
	}
});

export default router;
