<template>
	<page-template>
		<template slot="title">Dashboard</template>
		<!-- <template slot="buttons"></template> -->
		<!-- <template slot="appBarExtension">extension</template> -->
		<template slot="content">content slot</template>
	</page-template>
</template>

<script>
import PageTemplate from "../templates/PageTemplate";

export default {
	name: "home",
	components: { PageTemplate },
	mounted() {
		this.showProgressBar();
	},
	methods: {
		someHomeAction() {},
	},
};
</script>
