<template>
	<div>
		<v-app-bar app clipped-left color="primary">
			<!-- <v-app-bar-nav-icon
				@click.stop="
					$store.state.navigationDrawer.state = !$store.state.navigationDrawer
						.state
				"
      />-->
			​
			<v-toolbar-title> <slot name="title"></slot> </v-toolbar-title>​ <v-spacer />​
			<slot name="buttons">
				<span>V {{ version }}</span> </slot
			>​
			<template v-slot:extension v-if="hasAppBarExtension">
				<slot name="appBarExtension"></slot>
			</template>
		</v-app-bar>
		<slot name="content"></slot>
	</div>
</template>
<!-- USAGE -->
<!--
  <page-template>
    <template slot="title">page title slot</template>
    <template slot="buttons">menu buttons slot</template>
    <template slot="content">
      content slot
    </template>
  </page-template>
-->
​
<script>
export default {
	props: { hasAppBarExtension: Boolean },
	data() {
		return {
			version: process.env.PACKAGE_VERSION || "0.0.0",
		};
	},
};
</script>
