<template v-slot:extention v-if="needValidate">
	<v-autocomplete
		v-model="selected_value"
		v-bind:disabled="disabled"
		:items="users"
		label="User"
		item-text="email"
		item-value="id"
		:error-messages="errorMessages"
		@change="$emit('change')"
		@blur="$emit('blur')"
	/>
</template>

<script>
import Vue from "vue";

export default {
	props: ["value", "disabled", "errorMessages"],

	watch: {
		value: function (newValue, oldValue) {
			if (newValue !== this.selected_value) this.selected_value = newValue;

			this.$emit(
				"v-model",
				this.users.filter((user) => user.id === this.selected)
			);
			console.log(
				"WATCH",
				this.users.filter((user) => user.id === this.selected)
			);
		},
	},

	data() {
		return {
			users: null,
		};
	},

	computed: {
		selected_value: {
			get: function () {
				return this.value;
			},
			set: function (newValue) {
				this.selected = newValue;
				this.$emit("input", newValue);
			},
		},
	},

	created() {
		this.loadUsers();
	},

	methods: {
		onChange() {
			this.$emit("change");
		},

		onBlur() {
			this.$emit("blur");
		},

		handleSelect(newValue) {
			this.selected_value = newValue;
		},

		loadUsers() {
			Vue.axios
				.get(`${process.env.VUE_APP_AUTH_BASE_URL}users`)
				.then((response) => {
					this.users = response.data;
				});
		},
	},
};
</script>
