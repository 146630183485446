import Vue from "vue";

export const state = {
	countries: null,
	currencies: null,
	// ports: null,
	shippingTerms: null,
	units: null,
};

export const getters = {
	isReady(state, getters, rootState, rootGetters) {
		return (
			!rootGetters["auth/isAuthenticated"] ||
			(state.countries &&
				state.currencies &&
				// state.ports &&
				state.shippingTerms &&
				state.units)
		);
	},
};

export const mutations = {
	SET_COUNTRIES(state, list) {
		state.countries = list;
	},
	SET_CURRENCIES(state, list) {
		state.currencies = list;
	},
	// TODO: Port icin gerekli mi? (Portlar Countrylerin altinda tutuluyor)
	SET_PORTS(state, list) {
		state.ports = list;
	},
	SET_SHIPPING_TERMS(state, list) {
		state.shippingTerms = list;
	},
	SET_UNITS(state, list) {
		state.units = list;
	},
};

export const actions = {
	loadStructure({ commit }, { type, url, onsuccess, onFail }) {
		Vue.prototype.$http
			.get(url)
			.then((response) => {
				commit(type, response.data.items);
				if (onsuccess) onsuccess(response);
			})
			.catch((e) => {
				if (onFail) onFail(e);
			});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
