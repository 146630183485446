<template>
	<page-template>
		<template slot="title">Company</template>
		<template slot="content" v-if="company">
			<v-container>
				<v-card color="dark" dark outlined>
					<v-col>
						<v-row>
							<v-subheader class="ma-2"> [LG] {{ company.file }} </v-subheader>
							<v-card-title class="headline">
								{{ company.name }}
							</v-card-title>
							<v-spacer />
							<v-btn
								class="ma-2"
								elevation="2"
								icon
								@click="dialog.show(company)"
							>
								<v-icon> mdi-pencil-box-outline </v-icon>
							</v-btn>
						</v-row>
					</v-col>
					<v-divider />
					<v-card-text>
						<v-row>
							<v-col>
								<div>
									Country:
									{{ company.country.name }}
								</div>
								<div>Address: {{ company.address }}</div>
								<div>Status: {{ company.status }}</div>
								<div>Subscription Status: {{ company.subscriptionStatus }}</div>
							</v-col>
							<v-col>
								<div>Phone Number: {{ company.phoneNumber }}</div>
								<div>E-mail: {{ company.email }}</div>
								<div>Website: {{ company.website }}</div>
							</v-col>
						</v-row>
					</v-card-text>
					<v-divider />
					<v-toolbar>
						<v-toolbar-title>
							Employees
							<v-btn text @click="dialog.showEmployee(null)">
								New
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-toolbar-title>
					</v-toolbar>
					<v-data-table
						:headers="headersEmployee"
						:items="employees"
						:items-per-page="5"
						class="ma-4"
						style="curser: pointer"
						@click:row="dialog.showEmployee"
					/>
					<v-divider />
					<v-toolbar>
						<v-toolbar-title>
							Subscriptions
							<v-btn text @click="dialog.showSubscription(null)">
								New
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-toolbar-title>
					</v-toolbar>
					<v-data-table
						:headers="headersSubscription"
						:items="company.subscriptions"
						:items-per-page="5"
						class="ma-4"
						style="curser: pointer"
						@click:row="dialog.showSubscription"
					/>
				</v-card>
			</v-container>

			<v-fab-transition>
				<v-btn
					color="secondary"
					absolute
					bottom
					right
					fab
					class="mb-12"
					@click="dialog.show(null)"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Company</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field
							label="Name"
							v-model="dialog.item.name"
							:error-messages="nameErrors"
							@change="$v.dialog.item.name.$touch()"
							@input="$v.dialog.item.name.$touch()"
							@blur="$v.dialog.item.name.$touch()"
						/>
						<country-select
							v-model="dialog.item.countryId"
							:error-messages="countryErrors"
							@change="$v.dialog.item.countryId.$touch()"
							@blur="$v.dialog.item.countryId.$touch()"
						/>
						<v-text-field label="Address" v-model="dialog.item.address" />
						<v-text-field
							label="Phone Number"
							v-model="dialog.item.phoneNumber"
						/>
						<v-text-field label="E-mail" v-model="dialog.item.email" />
						<v-text-field label="Website" v-model="dialog.item.website" />
						<v-file-input
							label="Logo"
							v-model="dialog.item.file"
							prepend-icon="mdi-paperclip"
							placeholder="Select your files"
							outlined
						>
							<template v-slot:selection="{ text }">
								<v-chip v-if="text" color="accent" label small>
									{{ text }}
								</v-chip>
							</template>
						</v-file-input>
						<status-select
							v-if="!dialog.item.new"
							v-model="dialog.item.status"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn color="warning" text @click="deleteCompany(dialog.item)">
							Delete
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveCompany(dialog.item)">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialog.visibilityEmployee" width="500">
				<v-card>
					<v-card-title class="headline">Employee</v-card-title>
					<v-card-text v-if="dialog.employee">
						<v-select
							v-model="dialog.employee.user"
							:items="dummyEmployeeUser"
							label="User"
							item-text="email"
							item-value="id"
							:error-message="employeeUserErrors"
							@change="$v.dialog.employee.user.$touch()"
							@blur="$v.dialog.employee.user.$touch()"
						/>
						<v-select
							v-model="dialog.employee.type"
							:items="employeeTypesList"
							label="Type"
							item-text="type"
							item-value="value"
							:error-messages="employeeTypeErrors"
							@change="$v.dialog.employee.type.$touch()"
							@blur="$v.dialog.employee.type.$touch()"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="warning"
							text
							@click="deleteEmployee(dialog.employee)"
						>
							Delete
						</v-btn>
						<v-spacer />
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveEmployee(dialog.employee)">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialog.visibilitySubscription" width="500">
				<v-card>
					<v-card-title class="headline">Subscription</v-card-title>
					<v-card-text v-if="dialog.subscription">
						<v-select
							v-if="dialog.isNew"
							label="Antrego Product"
							v-model="dialog.subscription.id"
							:items="antProducts"
							item-text="name"
							item-value="id"
						/>
						<div v-else>
							<div>Product Name: {{ dialog.subscription.product.name }}</div>
							<div>Expires At: {{ dialog.subscription.expiresAt }}</div>
							<div>Modules: {{ dialog.subscription.product.modules }}</div>
							<div>
								Duration: {{ dialog.subscription.product.duration.value }}
								{{ dialog.subscription.product.duration.unit }}
							</div>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="warning"
							text
							@click="deleteSubscription(dialog.subscription)"
						>
							Delete
						</v-btn>
						<v-spacer />
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn
							v-if="dialog.isNew"
							color="accent"
							text
							@click="saveSubscription(dialog.subscription)"
						>
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import PageTemplate from "../templates/PageTemplate";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import StatusSelect from "../controls/StatusSelect";
import CountrySelect from "../controls/CountrySelect";
import UserSelect from "../controls/UserSelect";
import { mapActions, mapGetters, mapState } from "vuex";
// TODO: UserSelect will be change with new design

export default {
	name: "Company",
	mixins: [validationMixin],
	components: {
		PageTemplate,
		StatusSelect,
		CountrySelect,
		UserSelect,
	},

	validations: {
		dialog: {
			item: {
				name: { required },
				countryId: { required },
			},
			employee: {
				user: { required },
				type: { required },
			},
		},
	},

	computed: {
		...mapState(["countries"]),

		nameErrors() {
			const errors = [];
			if (this.$v.dialog.item.name.$dirty) {
				!this.$v.dialog.item.name.required && errors.push("Name is required");
			}
			return errors;
		},

		countryErrors() {
			const errors = [];
			if (this.$v.dialog.item.countryId.$dirty) {
				!this.$v.dialog.item.countryId.required &&
					errors.push("Country is required");
			}
			return errors;
		},

		employeeUserErrors() {
			const errors = [];
			if (this.$v.dialog.employee.user.$dirty) {
				!this.$v.dialog.employee.user.required &&
					errors.push("Employee user is required");
			}
			return errors;
		},

		employeeTypeErrors() {
			const errors = [];
			if (this.$v.dialog.employee.type.$dirty) {
				!this.$v.dialog.employee.type.required &&
					errors.push("Employee type is required");
			}
			return errors;
		},
	},

	data() {
		return {
			dateMenu: false,
			antProducts: null,

			employeeTypes: {
				1: "Default",
				2: "Admin",
			},

			employeeTypesList: [
				{ value: 1, type: "Default" },
				{ value: 2, type: "Admin" },
			],

			headersEmployee: [
				{ text: "E-mail", value: "user.email" },
				{ text: "Employee Type", value: "employeeTypeText" },
				{ text: "Status", value: "status" },
			],

			headersSubscription: [
				{ text: "Name", value: "product.name" },
				{ text: "Expires At", value: "expiresAt" },
			],

			dummyEmployeeUser: [
				{
					username: "salihtopcu@hotmail.com",
					email: "salihtopcu@hotmail.com",
					firstName: "Salih",
					lastName: "Topcu",
					id: "5f63459916cf7452c04d5b8e",
					type: 9,
				},
			],

			company: null,
			employees: [],
			subscriptions: [],

			dialog: {
				visibility: false,
				visibilityEmployee: false,
				visibilitySubscription: false,
				isNew: null,
				item: null,
				employee: null,
				subscription: null,

				show: (company) => {
					this.dialog.item = company
						? Object.assign({}, company)
						: {
								name: "",
								countryId: "",
								address: "",
								phoneNumber: "",
								email: "",
								website: "",
								file: null,
								logo: null,
								status: 1,
								new: true,
						  };
					this.dialog.visibility = true;
				},
				showEmployee: (employee) => {
					this.dialog.employee = employee
						? Object.assign({}, employee)
						: {
								user: {
									username: "salihtopcu@hotmail.com",
									email: "salihtopcu@hotmail.com",
									firstName: "Salih",
									lastName: "Topcu",
									id: "5f63459916cf7452c04d5b8e",
									type: 9,
								},
								type: "",
						  };
					this.dialog.visibilityEmployee = true;
				},
				showSubscription: (subscription) => {
					this.loadAntProducts();
					this.dialog.isNew = subscription === null;
					this.dialog.subscription = this.dialog.isNew
						? { id: null }
						: Object.assign({}, subscription);
					this.dialog.visibilitySubscription = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.employee = null;
					this.dialog.visibility = false;
					this.dialog.visibilityEmployee = false;
					this.dialog.visibilitySubscription = false;
				},
			},
		};
	},

	created() {
		this.loadCompany();
		this.loadEmployees();
	},

	methods: {
		...mapActions("file", ["sendFile"]),

		loadCompany() {
			this.$http.get(`companies/${this.$route.params.id}`).then((response) => {
				this.company = response.data;
				this.company.country = this.findCountry(this.company.countryId) ?? "";
				for (const sub of this.company.subscriptions) {
					sub.expiresAt = sub.expiresAt.substring(0, 10);
				}
			});
		},

		saveCompany(company) {
			this.$v.dialog.item.$touch();
			console.log("SAVE", company.subscriptions.expiresAt);
			this.sendFile({
				file: company.file,
				folder: company.id ?? 0,
				onsuccess: (response) => {
					company.logo = {};
					company.logo.name = response.data.name;
					company.logo.type = response.data.type;
					console.log("LOGO", company.logo);
				},
				onFail: (e) => console.error("ERR", e),
			}).then(() => {
				if (company.id) {
					this.$http
						.put(`companies/${company.id}`, company, {})
						.then((response) => {
							this.dialog.hide();
							this.loadCompany();
						});
				} else {
					this.$http.post(`companies`, company, {}).then((response) => {
						this.dialog.hide();
						this.loadCompany();
					});
				}
			});
		},

		async deleteCompany(company) {
			if (company.id) {
				this.$http
					.delete(`companies/${company.id}`, company, {})
					.then((response) => {
						this.dialog.hide();
					});
				this.$router.go(-1);
			} else this.dialog.hide();
		},

		loadEmployees() {
			this.$http
				.get(`companies/${this.$route.params.id}/employees`)
				.then((response) => {
					this.employees = response.data.items ?? {};
					for (const employee of this.employees) {
						employee.employeeTypeText = this.employeeTypes[employee.type];
					}
				});
		},

		saveEmployee(employee) {
			this.$v.dialog.employee.$touch();
			employee.corporationId = this.$route.params.id;

			if (employee.id) {
				this.$http
					.put(`employees/${employee.id}`, employee, {})
					.then((response) => {
						this.dialog.hide();
						this.loadEmployees();
					});
			} else {
				this.$http.post("employees", employee, {}).then((response) => {
					this.dialog.hide();
					this.loadEmployees();
				});
			}
		},

		deleteEmployee(employee) {
			if (employee.id) {
				this.$http
					.delete(`employees/${employee.id}`, employee, {})
					.then((response) => {
						this.dialog.hide();
						this.loadEmployees();
					});
			} else this.dialog.hide();
		},

		loadAntProducts() {
			this.$http.get("antrego/products").then((response) => {
				this.antProducts = response.data.items;
			});
		},

		saveSubscription(subscription) {
			var product = this.antProducts.find((p) => p.id == subscription.id);
			var body = {
				id: product.id,
				name: product.name,
				modules: product.modules,
				duration: product.duration,
			};
			this.$http
				.post(`companies/${this.$route.params.id}/subscriptions`, body, {})
				.then(() => {
					this.dialog.hide();
					this.loadCompany();
				});
		},

		deleteSubscription(subscription) {
			this.$http
				.delete(
					`companies/${this.$route.params.id}/subscriptions/${subscription.id}`,
					{}
				)
				.then(() => {
					this.dialog.hide();
					this.loadCompany();
				});
		},
	},
};
</script>
