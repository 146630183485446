<template>
	<page-template>
		<template slot="title">Companies</template>
		<template slot="content" v-if="companies">
			<v-data-table
				:headers="headers"
				:items="companies"
				:items-per-page="10"
				class="ma-4"
				style="curser: pointer"
				@click:row="companyAction"
				:search="search"
				:custom-filter="filterText"
			>
				<template v-slot:top>
					<v-text-field hide-details filled v-model="search" label="Search" />
				</template>
			</v-data-table>

			<v-fab-transition>
				<v-btn
					color="secondary"
					absolute
					bottom
					right
					fab
					class="mb-12"
					@click="dialog.show(null)"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Company</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field
							label="Name"
							v-model="dialog.item.name"
							:error-messages="nameErrors"
							@change="$v.dialog.item.name.$touch()"
							@input="$v.dialog.item.name.$touch()"
							@blur="$v.dialog.item.name.$touch()"
						/>
						<country-select
							v-model="dialog.item.countryId"
							:error-messages="countryErrors"
							@change="$v.dialog.item.countryId.$touch()"
							@blur="$v.dialog.item.countryId.$touch()"
						/>
						<v-text-field label="Address" v-model="dialog.item.address" />
						<v-text-field
							label="Phone Number"
							v-model="dialog.item.phoneNumber"
						/>
						<v-text-field label="E-mail" v-model="dialog.item.email" />
						<v-text-field label="Website" v-model="dialog.item.website" />
						<v-file-input
							label="Logo"
							v-model="dialog.item.file"
							prepend-icon="mdi-paperclip"
							placeholder="Select your files"
							outlined
						>
							<template v-slot:selection="{ text }">
								<v-chip v-if="text" color="accent" label small>
									{{ text }}
								</v-chip>
							</template>
						</v-file-input>
						<status-select
							v-if="!dialog.item.new"
							v-model="dialog.item.status"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn color="warning" text @click="deleteCompany(dialog.item)">
							Delete
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveCompany(dialog.item)">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import PageTemplate from "../templates/PageTemplate";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import StatusSelect from "../controls/StatusSelect";
import CountrySelect from "../controls/CountrySelect";
import { mapActions } from "vuex";

export default {
	name: "Companies",

	mixins: [validationMixin],

	components: {
		PageTemplate,
		StatusSelect,
		CountrySelect,
	},

	validations: {
		dialog: {
			item: {
				name: { required },
				countryId: { required },
			},
		},
	},

	computed: {
		nameErrors() {
			const errors = [];
			if (this.$v.dialog.item.name.$dirty) {
				!this.$v.dialog.item.name.required && errors.push("Name is required");
			}
			return errors;
		},

		countryErrors() {
			const errors = [];
			if (this.$v.dialog.item.countryId.$dirty) {
				!this.$v.dialog.item.countryId.required &&
					errors.push("Country is required");
			}
			return errors;
		},
	},

	data: () => ({
		companies: [],
		search: "",

		dialog: {
			visibility: false,
			item: null,
			show(company) {
				this.item = company
					? Object.assign({}, company)
					: {
							name: "",
							countryId: "",
							address: "",
							phoneNumber: "",
							email: "",
							website: "",
							file: null,
							logo: null,
							status: 1,
							new: true,
					  };
				this.visibility = true;
			},
			hide() {
				this.item = null;
				this.visibility = false;
			},
		},
		headers: [
			{ text: "Name", value: "name" },
			{ text: "Country", value: "country.name" },
			// { text: "Country ID", value: "countryId" },
			// { text: "Address", value: "address" },
			{ text: "Website", value: "website" },
			// { text: "E-mail", value: "email" },
			{ text: "Phone Number", value: "phoneNumber" },
			// { text: "Logo", value: "logo" },
			{ text: "Status", value: "status" },
		],
	}),

	created() {
		this.loadCompanies();
	},

	methods: {
		...mapActions("file", ["sendFile"]),
		...mapActions("root", ["loadCountries"]),
		...mapActions(["getCountry"]),

		filterText(value, search, item) {
			return (
				value != null &&
				search != null &&
				typeof value === "string" &&
				value.toString().toLocaleLowerCase().indexOf(search.toLowerCase()) !==
					-1
			);
		},

		companyAction(company) {
			this.$router.push({
				name: "company",
				params: { id: company.id },
			});
		},

		loadCompanies() {
			this.$http.get(`companies`).then((response) => {
				this.companies = response.data.items;
				//TODO: If there is clean code, change with that
				for (const c of this.companies)
					c.country = this.findCountry(c.countryId) ?? "";
			});
		},

		saveCompany(company) {
			this.$v.dialog.item.$touch();
			this.sendFile({
				file: company.file,
				folder: company.id ?? 0,
				onsuccess: (res) => {
					company.logo.name = res.data.name;
					company.logo.type = res.data.type;
				},
				onFail: (e) => console.error("ERR", e),
			}).then(() => {
				if (company.id) {
					this.$http
						.put(`companies/${company.id}`, company, {})
						.then((response) => {
							this.dialog.hide();
							this.loadCompanies();
						});
				} else {
					this.$http.post(`companies`, company, {}).then((response) => {
						this.dialog.hide();
						this.loadCompanies();
					});
				}
			});
		},

		deleteCompany(company) {
			if (company.id) {
				this.$http
					.delete(`companies/${company.id}`, company, {})
					.then((response) => {
						this.dialog.hide();
						this.loadCompanies();
					});
			} else this.dialog.hide();
		},
	},
};
</script>
