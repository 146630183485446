<template>
	<page-template>
		<template slot="title">Users</template>
		<template slot="content">
			<v-data-table
				:headers="headers"
				:items="users"
				:items-per-page="10"
				class="ma-4"
				style="curser: pointer"
				@click:row="dialog.show"
				:search="search"
				:custom-filter="filterText"
			>
				<template v-slot:top>
					<v-text-field hide-details filled v-model="search" label="Search" />
				</template>
			</v-data-table>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Users</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field label="First Name" v-model="dialog.item.firstName" disabled />
						<v-text-field label="Last Name" v-model="dialog.item.lastName" disabled />
						<v-text-field label="E-mail" v-model="dialog.item.email" disabled />
						<v-text-field label="Password" v-model="dialog.item.password" disabled />
						<v-select v-model="dialog.item.type" label="Type" :items="type" item-text="title" item-value="value" disabled />
						<status-select label="Status" v-model="dialog.item.status" disabled />
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text @click="dialog.hide()">Done</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import Vue from "vue";
import PageTemplate from "../templates/PageTemplate";
import StatusSelect from "../controls/StatusSelect";
import { mapActions } from "vuex";

export default {
	name: "Users",
	components: { PageTemplate, StatusSelect },

	data() {
		return {
			search: "",
			users: [],

			types: {
				1: "Default",
				2: "Admin",
				9: "Super",
			},

			statuses: {
				0: "Passive",
				1: "Active",
			},

			type: [
				{
					title: "Default",
					value: 1,
				},
				{
					title: "Admin",
					value: 2,
				},
				{
					title: "Super",
					value: 9,
				},
			],

			dialog: {
				visibility: false,
				item: null,
				show: (user) => {
					this.dialog.item = Object.assign({}, user);
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
			},
			headers: [
				// { text: "ID", value: "id" },
				{ text: "First Name", value: "firstName" },
				{ text: "Last Name", value: "lastName" },
				{ text: "E-mail", value: "email" },
				{ text: "Type", value: "typeText" },
				{ text: "Status", value: "statusText" },
				{ text: "Created At", value: "createdAt" },
			],
		};
	},

	created() {
		this.loadUsers();
	},

	methods: {
		filterText(value, search, item) {
			return (
				value != null && search != null && typeof value === "string" && value.toString().toLocaleLowerCase().indexOf(search.toLowerCase()) !== -1
			);
		},

		loadUsers() {
			Vue.axios.get(`${process.env.VUE_APP_AUTH_BASE_URL}users/`).then((response) => {
				this.users = response.data;
				for (const user of this.users) {
					user.typeText = this.types[user.type];
					user.statusText = this.statuses[user.status];
					user.createdAt = user.createdAt.substr(0, 10) + " " + user.createdAt.substr(11, 8);
				}
			});
		},
	},
};
</script>
