<template>
	<page-template>
		<template slot="title">Antrego</template>
		<template slot="content">
			<v-data-table
				:headers="headers"
				:items="applications"
				:items-per-page="10"
				class="ma-4"
				style="curser: pointer"
			/>

			<v-fab-transition>
				<v-btn
					color="secondary"
					absolute
					bottom
					right
					fab
					class="mb-12"
					@click="dialog.show"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Applications</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field
							label="Code"
							v-model="dialog.item.code"
							:error-messages="codeErrors"
							@change="$v.dialog.item.code.$touch()"
							@input="$v.dialog.item.code.$touch()"
							@blur="$v.dialog.item.code.$touch()"
						/>
						<status-select
							v-model="dialog.item.status"
							v-if="!dialog.item.new"
						/>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveApplication(dialog.item)">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import Vue from "vue";
import PageTemplate from "../templates/PageTemplate";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import StatusSelect from "../controls/StatusSelect";

export default {
	name: "Applications",
	mixins: [validationMixin],
	components: { PageTemplate, StatusSelect },

	validations: {
		dialog: {
			item: {
				code: { required },
			},
		},
	},

	computed: {
		codeErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty)
				!this.$v.dialog.item.code.required && errors.push("Code is required");
			return errors;
		},
	},

	data() {
		return {
			applications: [],

			dialog: {
				visibility: false,
				item: null,
				show: () => {
					this.dialog.item = {
						code: "",
						status: 1,
					};
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
			},
			headers: [
				{ text: "Code", value: "code" },
				{ text: "Status", value: "status" },
				{ text: "ID", value: "id" },
			],
		};
	},

	created() {
		this.loadApplications();
	},

	methods: {
		loadApplications() {
			Vue.axios
				.get(`${process.env.VUE_APP_AUTH_BASE_URL}applications/`)
				.then((response) => {
					this.applications = response.data;
				});
		},
		saveApplication(application) {
			this.$v.dialog.item.$touch();
			Vue.axios
				.post(
					`${process.env.VUE_APP_AUTH_BASE_URL}applications/`,
					application,
					{}
				)
				.then((response) => {
					this.dialog.hide();
					this.loadApplications();
				});
		},
	},
};
</script>
