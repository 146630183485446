const state = {
	progressBar: {
		visibility: false,
		animating: true,
	},
	snackBar: {
		visibility: false,
		message: String,
		info: String,
		timeout: 3000,
	},
	alertDialog: {
		visibility: false,
		title: String,
		message: String,
	},
	confirmDialog: {
		visibility: false,
		title: String,
		message: String,
		confirmButtonText: String,
		cancelButtonText: String,
		onConfirm: Function,
	},
};

const getters = {};

const mutations = {
	SET_PROGRESS_STATE(state, visibility) {
		state.progressBar.visibility = visibility;
	},
	SET_SNACK_BAR_STATE(state, visibility) {
		state.snackBar.visibility = visibility;
	},
	SET_ALERT_DIALOG_STATE(state, visibility) {
		state.alertDialog.visibility = visibility;
	},
	SET_CONFIRM_DIALOG_STATE(state, visibility) {
		state.confirmDialog.visibility = visibility;
	},
};

const actions = {
	showProgressBar({ commit, state }) {
		state.progressBar.animating = true;
		commit("SET_PROGRESS_STATE", true);
	},
	hideProgressBar({ commit, state }) {
		state.progressBar.animating = false;
		commit("SET_PROGRESS_STATE", false);
	},
	showSnackBar({ commit, state }, { message, color, timeout }) {
		state.snackBar.message = message || "NO TEXT!";
		state.snackBar.color = color || "accent";
		state.snackBar.timeout = timeout || 3000;
		commit("SET_SNACK_BAR_STATE", true);
	},
	hideSnackBar({ commit }) {
		commit("SET_SNACK_BAR_STATE", false);
	},
	showAlertDialog({ commit, state }, { title, message }) {
		state.alertDialog.title = title || "Alert!";
		state.alertDialog.message = message || "NO TEXT!";
		commit("SET_ALERT_DIALOG_STATE", true);
	},
	hideAlertDialog({ commit }) {
		commit("SET_ALERT_DIALOG_STATE", false);
	},
	showConfirmDialog(
		{ commit, state },
		{ title, message, confirmButtonText, cancelButtonText, onConfirm }
	) {
		state.confirmDialog.title = title || "Confirm";
		state.confirmDialog.message = message || "NO TEXT!";
		state.confirmDialog.confirmButtonText = confirmButtonText || "Yes";
		state.confirmDialog.cancelButtonText = cancelButtonText || "Cancel";
		state.confirmDialog.onConfirm = onConfirm;
		if (onConfirm && {}.toString.call(onConfirm) === "[object Function]")
			commit("SET_CONFIRM_DIALOG_STATE", true);
	},
	hideConfirmDialog({ commit }) {
		commit("SET_CONFIRM_DIALOG_STATE", false);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
