<template>
	<page-template>
		<template slot="title">Country</template>
		<template slot="content" v-if="country">
			<v-container>
				<v-card color="dark" dark outlined>
					<v-col>
						<v-row>
							<v-subheader class="ma-2">{{ country.code }}</v-subheader>
							<v-card-title class="headline">
								{{ country.name }}
							</v-card-title>
							<v-spacer />
							<v-btn
								class="ma-2"
								elevation="2"
								icon
								@click="dialog.show(country)"
							>
								<v-icon> mdi-pencil-box-outline </v-icon>
							</v-btn>
						</v-row>
					</v-col>
					<v-divider />
					<v-toolbar>
						<v-toolbar-title>
							Ports
							<v-btn text @click="dialog.showPort(null)">
								New
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-toolbar-title>
					</v-toolbar>
					<v-data-table
						:headers="headers"
						:items="country.ports"
						:items-per-page="5"
						class="ma-4"
						style="curser: pointer"
						@click:row="dialog.showPort"
					/>
				</v-card>
			</v-container>

			<v-fab-transition>
				<v-btn
					color="secondary"
					absolute
					bottom
					right
					fab
					class="mb-12"
					@click="dialog.show(null)"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Country</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field
							label="Name"
							v-model="dialog.item.name"
							:error-messages="nameErrors"
							@change="$v.dialog.item.name.$touch()"
							@input="$v.dialog.item.name.$touch()"
							@blur="$v.dialog.item.name.$touch()"
						/>
						<v-text-field
							label="Code"
							counter="2"
							v-model="dialog.item.code"
							:error-messages="codeErrors"
							@change="$v.dialog.item.code.$touch()"
							@input="$v.dialog.item.code.$touch()"
							@blur="$v.dialog.item.code.$touch()"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn color="warning" text @click="deleteCountry(dialog.item)">
							Delete
						</v-btn>
						<v-spacer />
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveCountry(dialog.item)">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialog.visibilityPort" width="500">
				<v-card>
					<v-card-title class="headline">Port</v-card-title>
					<v-card-text v-if="dialog.port">
						<v-text-field
							label="Name"
							v-model="dialog.port.name"
							:error-messages="portNameErrors"
							@change="$v.dialog.port.name.$touch()"
							@input="$v.dialog.port.name.$touch()"
							@blur="$v.dialog.port.name.$touch()"
						/>
						<v-text-field
							label="Code"
							counter="5"
							v-model="dialog.port.code"
							:error-messages="portCodeErrors"
							@change="$v.dialog.port.code.$touch()"
							@input="$v.dialog.port.code.$touch()"
							@blur="$v.dialog.port.code.$touch()"
						/>
						<v-select
							label="Type"
							v-model="dialog.port.type"
							:items="portTypeItems"
							:error-messages="portTypeErrors"
							@change="$v.dialog.port.type.$touch()"
							@input="$v.dialog.port.type.$touch()"
							@blur="$v.dialog.port.type.$touch()"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn color="warning" text @click="deletePort(dialog.port)">
							Delete
						</v-btn>
						<v-spacer />
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="savePort(dialog.port)">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import PageTemplate from "../templates/PageTemplate";

export default {
	name: "Country",
	mixins: [validationMixin],
	components: { PageTemplate },

	validations: {
		dialog: {
			item: {
				name: { required, maxLength: maxLength(30) },
				code: { required, minLength: minLength(2), maxLength: maxLength(2) },
			},
			port: {
				name: { required },
				code: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(5),
				},
				type: { required },
			},
		},
	},

	computed: {
		...mapState(["countries"]),

		nameErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty)
				!this.$v.dialog.item.name.required && errors.push("Name is required");
			!this.$v.dialog.item.name.maxLength &&
				errors.push("Name must be max 30 characters long");
			return errors;
		},

		codeErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty) {
				!this.$v.dialog.item.code.required && errors.push("Code is required");
				!this.$v.dialog.item.code.minLength &&
					errors.push("Code must be 2 characters long");
				!this.$v.dialog.item.code.maxLength &&
					errors.push("Code must be 2 characters long");
			}
			return errors;
		},

		portNameErrors() {
			const errors = [];
			if (this.$v.dialog.port.name.$dirty)
				!this.$v.dialog.port.name.required && errors.push("Name is required");
			return errors;
		},

		portCodeErrors() {
			const errors = [];
			if (this.$v.dialog.port.code.$dirty) {
				!this.$v.dialog.port.code.required && errors.push("Code is required");
				!this.$v.dialog.port.code.minLength &&
					errors.push("Code must be min 3 characters long");
				!this.$v.dialog.port.code.maxLength &&
					errors.push("Code must be max 5 characters long");
			}
			return errors;
		},

		portTypeErrors() {
			const errors = [];
			if (this.$v.dialog.port.type.$dirty)
				!this.$v.dialog.port.type.required && errors.push("Type is required");
			return errors;
		},
	},

	data() {
		return {
			country: null,
			portTypeItems: ["seaport", "airport"],

			dialog: {
				visibility: false,
				visibilityPort: false,
				item: null,
				port: null,
				show: (country) => {
					this.dialog.item = country
						? Object.assign({}, country)
						: { name: "", code: "", ports: [], status: 1, new: true };
					this.dialog.visibility = true;
				},
				showPort: (port) => {
					this.dialog.port = port
						? Object.assign({}, port)
						: { id: null, name: "", code: "", new: true };
					this.dialog.visibilityPort = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.port = null;
					this.dialog.visibility = false;
					this.dialog.visibilityPort = false;
				},
			},
			headers: [
				{ text: "Name", value: "name" },
				{ text: "Code", value: "code" },
				{ text: "Type", value: "type" },
			],
		};
	},

	created() {
		this.loadCountry();
		this.loadPorts();
	},

	methods: {
		...mapActions(["loadStructure"]),

		loadCountry() {
			this.$http.get(`countries/${this.$route.params.id}`).then((response) => {
				this.country = response.data;
			});
		},

		saveCountry(country) {
			this.$v.dialog.item.$touch();
			if (country.id) {
				this.$http
					.put(`countries/${country.id}`, country, {})
					.then((response) => {
						this.dialog.hide();
						this.loadCountry();
					});
			} else {
				this.$http.post(`countries`, country, {}).then((response) => {
					this.dialog.hide();
					this.loadCountry();
				});
			}
		},

		deleteCountry(country) {
			if (country.id) {
				this.$http
					.delete(`countries/${country.id}`, country, {})
					.then((response) => {
						this.dialog.hide();
						this.loadCountry();
					});
				console.log("object");
				this.$router.push("countries");
			} else this.dialog.hide();
		},

		loadPorts() {
			console.log("0", this.$route.params.id);
			this.$http
				.get(`countries/${this.$route.params.id}/ports`)
				.then((response) => {
					this.country.ports = response.data;
				});
		},

		savePort(port) {
			console.log("2", port);
			if (port.id) {
				this.$http
					.put(`countries/${this.$route.params.id}/ports/${port.id}`, port, {})
					.then((response) => {
						this.dialog.hide();
						this.loadPorts();
					});
			} else {
				this.$http
					.post(`countries/${this.$route.params.id}/ports`, port, {})
					.then((response) => {
						this.dialog.hide();
						this.loadPorts();
					});
			}
		},

		deletePort(port) {
			console.log("1", port);
			if (port.id) {
				this.$http
					.delete(
						`countries/${this.$route.params.id}/ports/${port.id}`,
						port,
						{}
					)
					.then((response) => {
						this.dialog.hide();
						this.loadPorts();
					});
			} else this.dialog.hide();
		},
	},
};
</script>
