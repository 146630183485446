<template>
	<page-template>
		<template slot="title">Units</template>
		<template slot="content">
			<v-data-table
				:headers="headers"
				:items="this.units"
				:items-per-page="10"
				class="ma-4"
				style="cursor: pointer"
				@click:row="dialog.show"
				:search="search"
				:custom-filter="filterText"
			>
				<template v-slot:top>
					<v-text-field hide-details filled v-model="search" label="Search" />
				</template>
			</v-data-table>

			<v-fab-transition>
				<v-btn
					color="secondary"
					absolute
					bottom
					right
					fab
					class="mb-12"
					@click="dialog.show(null)"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-fab-transition>

			<v-dialog v-model="dialog.visibility" width="500">
				<v-card>
					<v-card-title class="headline">Unit</v-card-title>
					<v-card-text v-if="dialog.item">
						<v-text-field
							label="Name"
							v-model="dialog.item.name"
							:error-messages="nameErrors"
							@change="$v.dialog.item.name.$touch()"
							@input="$v.dialog.item.name.$touch()"
							@blur="$v.dialog.item.name.$touch()"
						/>
						<v-text-field
							label="Code"
							counter="3"
							v-model="dialog.item.code"
							:error-messages="codeErrors"
							@change="$v.dialog.item.code.$touch()"
							@input="$v.dialog.item.code.$touch()"
							@blur="$v.dialog.item.code.$touch()"
						/>
						<status-select
							v-model="dialog.item.status"
							v-if="!dialog.item.new"
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn color="warning" text @click="deleteUnit(dialog.item)">
							Delete
						</v-btn>
						<v-spacer />
						<v-btn text @click="dialog.hide()">Cancel</v-btn>
						<v-btn color="accent" text @click="saveUnit(dialog.item)">
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</page-template>
</template>

<script>
import PageTemplate from "../templates/PageTemplate";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import StatusSelect from "../controls/StatusSelect";
import { mapActions, mapState } from "vuex";

export default {
	name: "Units",
	mixins: [validationMixin],
	components: { PageTemplate, StatusSelect },

	validations: {
		dialog: {
			item: {
				name: { required, maxLength: maxLength(32) },
				code: { required, maxLength: maxLength(3) },
			},
		},
	},

	computed: {
		...mapState(["units"]),

		nameErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty) {
				!this.$v.dialog.item.name.required && errors.push("Name is required");
				!this.$v.dialog.item.name.maxLength &&
					errors.push("Name must be max 10 characters long.");
			}
			return errors;
		},

		codeErrors() {
			const errors = [];
			if (this.$v.dialog.item.$dirty) {
				!this.$v.dialog.item.code.required && errors.push("Code is required");
				!this.$v.dialog.item.code.maxLength &&
					errors.push("Code must be max 3 characters long");
			}
			return errors;
		},
	},

	data() {
		return {
			search: "",

			dialog: {
				visibility: false,
				item: null,
				show: (unit) => {
					this.dialog.item = unit
						? Object.assign({}, unit)
						: {
								name: "",
								code: "",
								status: 1,
								new: true,
						  };
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
			},
			headers: [
				{ text: "Name", value: "name" },
				{ text: "Code", value: "code" },
				{ text: "Status", value: "status" },
			],
		};
	},

	created() {
		this.loadUnits();
	},
	methods: {
		...mapActions(["loadStructure"]),

		filterText(value, search, item) {
			return (
				value != null &&
				search != null &&
				typeof value === "string" &&
				value.toString().toLocaleLowerCase().indexOf(search.toLowerCase()) !==
					-1
			);
		},

		loadUnits() {
			this.loadStructure({
				url: `units`,
				type: "SET_UNITS",
			});
		},

		saveUnit(unit) {
			this.$v.dialog.item.$touch();
			if (unit.id) {
				this.$http.put(`units/${unit.id}`, unit, {}).then((response) => {
					this.dialog.hide();
					this.loadUnits();
				});
			} else {
				this.$http.post(`units`, unit, {}).then((response) => {
					this.dialog.hide();
					this.loadUnits();
				});
			}
		},

		deleteUnit(unit) {
			if (unit.id) {
				this.$http.delete(`units/${unit.id}`, unit, {}).then((response) => {
					this.dialog.hide();
					this.loadUnits();
				});
			} else this.dialog.hide();
		},
	},
};
</script>
